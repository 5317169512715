//
// user-variables.scss
// Use this to override Bootstrap and Silicon variables
//

// Example of a variable override to change Silicon background color
// Remove the "//" to comment it in and see it in action!
// $body-bg: #12263F;
$primary: #5499c7;
$path-to-fonts: "https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700;900&family=Poppins:wght@400;500;700;900&display=swap";
$font-family-sans-serif: "Poppins", "Noto Sans JP", sans-serif;

$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 700;
$font-weight-bold: 900;
